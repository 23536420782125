import { Routes } from "@angular/router";
import { RoutingIndexComponent } from "./routing/routing-index/routing-index.component";
import { AuthGuard } from "./services/auth.guard";

export const routes: Routes = [
  {
    path: "events",
    children: [
      {
        path: "",
        loadComponent: () => import('./routing/routing-event-single-page/routing-event-single-page.component').then(c => c.RoutingEventSinglePageComponent),
        data: {
          title: "Veranstaltungen | {SCHOOL}",
          ogUrl: "/events",
          description:
            "Für das besondere Erlebnis veranstalten wir regelmäßig Events für die verschiedenen Altersklassen! Feiert mit uns in unseren Sälen.",
        },
      },
      { path: ":ageClass", loadComponent: () => import('./routing/routing-event/routing-event.component').then(c => c.RoutingEventComponent) },
      { path: "anmelden/:eventId", loadComponent: () => import('./routing/routing-event-form/routing-event-form.component').then(c => c.RoutingEventFormComponent) },
    ],
  },
  {
    path: "kurse/:ageClass",
    children: [
      {
        path: "",
        loadComponent: () => import('./routing/routing-course-list/routing-course-list.component').then(c => c.RoutingCourseListComponent),
      },
      { path: ":courseLevelName", loadComponent: () => import('./routing/routing-course-level/routing-course-level.component').then(c => c.RoutingCourseLevelComponent) },
    ],
  },
  { path: "buchen/:id", loadComponent: () => import('./routing/routing-course-checkout/routing-course-checkout.component').then(c => c.RoutingCourseCheckoutComponent), },
  {
    path: "buchen/:id/:startDateId",
    loadComponent: () => import('./routing/routing-course-checkout/routing-course-checkout.component').then(c => c.RoutingCourseCheckoutComponent),
  },
  { path: "gebucht/:id", loadComponent: () => import('./routing/routing-course-checkout/routing-course-checkout.component').then(c => c.RoutingCourseCheckoutComponent), },
  {
    path: "kontakt",
    loadComponent: () => import('./routing/routing-contact/routing-contact.component').then(c => c.RoutingContactComponent),
    data: {
      title: "Kontakt | {SCHOOL}",
      ogUrl: "/kontakt",
    },
  },
  {
    path: "team",
    loadComponent: () => import('./routing/routing-team/routing-team.component').then(c => c.RoutingTeamComponent),
    data: {
      title: "Unser Team | {SCHOOL}",
      ogUrl: "/team",
      description: "Das Team der {SCHOOL}",
    },
  },
  {
    path: "saele",
    loadComponent: () => import('./routing/routing-room/routing-room.component').then(c => c.RoutingRoomComponent),
    data: {
      title: "Unsere Räumlichkeiten | {SCHOOL}",
      ogUrl: "/saele",
      description:
        "Wir haben mehrere Säle, ausgestattet mit moderner Lich- und Tontechnik, in denen wir das Tanzen unterrichten. Wir freuen und auf euch!",
    },
  },
  {
    path: "säle",
    loadComponent: () => import('./routing/routing-room/routing-room.component').then(c => c.RoutingRoomComponent),
    data: {
      title: "Unsere Räumlichkeiten | {SCHOOL}",
      ogUrl: "/saele",
      description:
        "Wir haben mehrere Säle, ausgestattet mit moderner Lich- und Tontechnik, in denen wir das Tanzen unterrichten. Wir freuen und auf euch!",
    },
  },
  {
    path: "taenze",
    loadComponent: () => import('./dance/dance-grid/dance-grid.component').then(c => c.DanceGridComponent),
  },
  {
    path: "getraenke",
    loadComponent: () => import('./routing/routing-drinks/routing-drinks.component').then(c => c.RoutingDrinksComponent),
    data: {
      title: "Getränke | {SCHOOL}",
      ogUrl: "/getraenke",
    },
  },
  { path: "news/:urlTitle", loadComponent: () => import('./routing/routing-news/routing-news.component').then(c => c.RoutingNewsComponent) },
  { path: "static/:titleUrl", loadComponent: () => import('./routing/routing-page/routing-page.component').then(c => c.RoutingPageComponent) },
  {
    path: "konditionen",
    loadComponent: () => import('./routing/routing-conditions/routing-conditions.component').then(c => c.RoutingConditionsComponent),
    data: {
      title: "Die Konditionen unserer Tanzkurse | {SCHOOL}",
      ogUrl: "/konditionen",
      description:
        "Wir möchten all unsere Tanzkurse zu fairen Konditionen anbieten und haben beispielsweise Preismodelle für verschiedene Laufzeiten.",
    },
  },
  {
    path: "kursfreie-zeiten",
    loadComponent: () => import('./routing/routing-freetime/routing-freetime.component').then(c => c.RoutingFreetimeComponent),
    data: {
      title: "Kursfreie Zeiten | {SCHOOL}",
      ogUrl: "/kursfreie-zeiten",
    },
  },
  {
    path: "privatunterricht",
    loadComponent: () => import('./routing/routing-private/routing-private.component').then(c => c.RoutingPrivateComponent),
    data: {
      title: "Privater Tanzunterricht | {SCHOOL}",
      ogUrl: "/privatunterricht",
    },
  },
  {
    path: "about",
    loadComponent: () => import('./routing/routing-about-us/routing-about-us.component').then(c => c.RoutingAboutUsComponent),
    data: {
      title: "Über uns | {SCHOOL}",
      ogUrl: "/about",
    },
  },
  {
    path: "kuendigung",
    loadComponent: () => import('./routing/routing-cancelation/routing-cancelation.component').then(c => c.RoutingCancelationComponent),
    data: {
      title: "Kündigung | {SCHOOL}",
      ogUrl: "/kuendigung",
    },
  },
  {
    path: "filme",
    loadComponent: () => import('./routing/routing-movies/routing-movies.component').then(c => c.RoutingMoviesComponent),
    data: {
      title: "Filme über uns | {SCHOOL}",
      ogUrl: "/filme",
      description:
        "Viel Spaß bei unserer kleinen Filmesammlung der Tanzschule.",
    },
  },
  { path: "film/:movieId", loadComponent: () => import('./routing/routing-movie/routing-movie.component').then(c => c.RoutingMovieComponent) },
  {
    path: "partner",
    loadComponent: () => import('./routing/routing-partners/routing-partners.component').then(c => c.RoutingPartnersComponent),
    data: {
      title: "Unsere Partner | {SCHOOL}",
      ogUrl: "/partner",
      description:
        "Wir sind dankbar für tolle Werbepartner in Wedel und Hamburg! Hier sehen Sie eine Übersicht der Vorteile, die Sie durch die Vorlage Ihrer Riemer-Card erhalten.",
    },
  },
  { path: "tv", loadComponent: () => import('./routing/routing-tv/routing-tv.component').then(c => c.RoutingTvComponent) },

  {
    path: "kundenbereich",
    data: {
      title: "Kundenbereich | {SCHOOL}",
      ogUrl: "/kundenbereich",
    },
    loadComponent: () => import('./routing/routing-customer/routing-customer.component').then(c => c.RoutingCustomerComponent),
    children: [
      {
        path: "galerie",
        loadComponent: () => import('./routing/routing-customer-gallery/routing-customer-gallery.component').then(c => c.RoutingCustomerGalleryComponent),
        data: {
          title: "Kundenbereich - Galerie | {SCHOOL}",
          ogUrl: "/kundenbereich/galerie",
        },
      },
      {
        path: "videos",
        loadComponent: () => import('./routing/routing-customer-video/routing-customer-video.component').then(c => c.RoutingCustomerVideoComponent),
        data: {
          title: "Kundenbereich - Mediathek | {SCHOOL}",
          ogUrl: "/kundenbereich/videos",
        },
      },
      {
        path: "masterclass/:year",
        loadComponent: () => import('./routing/routing-customer-video/routing-customer-video.component').then(c => c.RoutingCustomerVideoComponent),
        data: {
          title: "Kundenbereich - Masterclass | {SCHOOL}",
          ogUrl: "/kundenbereich/masterclass",
        },
      },
      {
        path: "kursprogramm",
        loadComponent: () => import('./routing/routing-customer-medium/routing-customer-medium.component').then(c => c.RoutingCustomerMediumComponent),
        data: {
          title: "Kundenbereich - Kursprogramm | {SCHOOL}",
          ogUrl: "/kundenbereich/kursprogramm",
        },
      },
      {
        path: "info",
        loadComponent: () => import('./routing/routing-customer-info/routing-customer-info.component').then(c => c.RoutingCustomerInfoComponent),
        data: {
          title: "Kundenbereich - Aktuelle Info | {SCHOOL}",
          ogUrl: "/kundenbereich/info",
        },
      },
    ],
  },
  { path: "impressum",  loadComponent: () => import('./routing/routing-imprint/routing-imprint.component').then(c => c.RoutingImprintComponent) },
  { path: "datenschutz", loadComponent: () => import('./routing/routing-datenschutz/routing-datenschutz.component').then(c => c.RoutingDatenschutzComponent) },
  { path: "agb", loadComponent: () => import('./routing/routing-agb/routing-agb.component').then(c => c.RoutingAgbComponent) },
  { path: "agb-app", loadComponent: () => import('./routing/routing-agb-higle-app/routing-agb-higle-app.component').then(c => c.RoutingAgbHigleAppComponent) },
  /* {
    path: "taenze",
    children: [
      { path: "", component: RoutingIndexComponent },
    ],
  }, */
  /* {
    path: "online",
    children: [
      { path: "", component: IndexComponent },
      { path: "login", component: LoginComponent },
      { path: "registrieren", component: RegisterComponent },
      { path: "preise", component: PricesComponent },
      { path: "faq", component: FaqComponent },
       {
        path: "portal",
        component: PortalIndexComponent,
        children: [
          {
            path: "live",
            component: PortalLiveComponent,
            outlet: "portal",
          },
          {
            path: "video/:videoId",
            component: PortalVideoComponent,
            outlet: "portal",
          },
          {
            path: ":videoCatId",
            component: PortalVideoCatComponent,
            outlet: "portal",
          },
        ],
      },
    ],
  }, */

  { path: "login", loadComponent: () => import('./routing/routing-login/routing-login.component').then(c => c.RoutingLoginComponent) },
  {
    path: 'backend/attributes',
    loadComponent: () => import('./backend/attribute-routing/attribute-routing.component').then(c => c.AttributeRoutingComponent),
  },
  {
    path: "backend",
    canActivate: [AuthGuard],
    loadComponent: () => import('./routing/routing-backend/routing-backend.component').then(c => c.RoutingBackendComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./backend/backend-routing/backend-routing.component').then(c => c.BackendRoutingComponent),
        outlet: 'backendOutlet',
      },
      {
        path: ':serviceName',
        loadComponent: () => import('./backend/backend-routing/backend-routing.component').then(c => c.BackendRoutingComponent),
        outlet: 'backendOutlet',
      },
      {
        path: ':serviceName/:itemId',
        loadComponent: () => import('./backend/backend-routing/backend-routing.component').then(c => c.BackendRoutingComponent),
        outlet: 'backendOutlet',
      },
      {
        path: ':serviceName/new',
        loadComponent: () => import('./backend/generic-form/generic-form.component').then(c => c.GenericFormComponent),
        outlet: 'backendItemOutlet',
      },
      {
        path: '',
        loadComponent: () => import('./backend/empty-state/empty-state.component').then(c => c.EmptyStateComponent),
        outlet: 'backendItemOutlet',
      },
      {
        path: ':serviceName/from/:itemId',
        loadComponent: () => import('./backend/generic-form/generic-form.component').then(c => c.GenericFormComponent),
        outlet: 'backendItemOutlet',
      },
      {
        path: ':serviceName/:itemId',
        loadComponent: () => import('./backend/generic-form/generic-form.component').then(c => c.GenericFormComponent),
        outlet: 'backendItemOutlet',
      },
    ],
  },
  { path: "screen/:id", component: RoutingIndexComponent },
  { path: "", component: RoutingIndexComponent },
  {path: '404', component: RoutingIndexComponent},
  {path: '**', redirectTo: '/404'}
];