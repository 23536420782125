import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { AgeClass } from "./ageClass";
import { DbObj } from "./dbObj";
import { EventTicketType } from './eventTicketType';
import { Question } from './question';

dayjs.extend(utc);
dayjs.extend(timezone);

export class Event implements DbObj {
  public id: number;
  public name: string;
  public description: string;
  public showExtraPage: boolean;
  public entryWithoutRegistration: boolean;
  public text: string | null;
  public img: string | null;
  public date: Date;
  public date_end: Date;
  public dateTicketCollection: Date;
  public locationText: string;
  public locationLink: string;
  public locationAddress: string;
  private fk_school: number;
  public ageClass: AgeClass;
  public questions: Question[];
  public eventTicketTypes: EventTicketType[];

  constructor() { }

  get prettyName() {
    return this.name;
  }

  get subtitle() {
    return dayjs(this.date).format("DD.MM.YYYY");
  }

  public getDateText() {
    const date = new Date(this.date);
    return dayjs(this.date).format("DD.MM.YYYY");
  }

  public getTimeText(newLine?: boolean) {
    const date = new Date(this.date);
    return `${dayjs(this.date).tz("Europe/Berlin").format("HH:mm")}${newLine ? "<br />" : " "
      }Uhr`;
  }

  public getTimeTextWithEnd() {
    const date = new Date(this.date);
    return `${dayjs(this.date).tz("Europe/Berlin").format("HH:mm")} - ${dayjs(
      this.date_end
    )
      .tz("Europe/Berlin")
      .format("HH:mm")} Uhr`;
  }
}
