import { Expose, Type } from "class-transformer";
import { IsArray, IsBoolean, IsNumber, IsString, ValidateNested } from "class-validator";
import { BaseEntity } from "../backend/baseEntity";
import { BackendColumnFullSizeDto } from "./backend-column.full-size.dto";

export class BackendFullSizeDto<T extends BaseEntity> {

  @IsNumber()
  @Expose()
  id: number;

  @IsString()
  @Expose()
  dbName: T['dbName'];

  @IsString()
  @Expose()
  prettyName: string;

  @IsString()
  @Expose()
  icon: string;

  @IsBoolean()
  @Expose()
  isOwnTable: boolean;

  @IsBoolean()
  @Expose()
  isDependentOnSchool: boolean;

  @IsBoolean()
  @Expose()
  onlyDeleteRefs: boolean;

  @IsBoolean()
  @Expose()
  canBeExported?: boolean;

  @IsArray()
  @ValidateNested({ each: true })
  @Expose()
  @Type(() => BackendColumnFullSizeDto<T>)
  columns: BackendColumnFullSizeDto<T>[];
}
